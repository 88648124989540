import React from 'react';
import { ImproveProductPopup } from './ImproveProductPopup';
import { Portal } from 'shared/lib/Portal/Portal';
import { useBoolState } from 'shared/hooks/useBoolState';
import { useTimeoutFn } from 'react-use';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';


export function ImproveProductPopupFrame(){
  const [isOpen, open, close] = useBoolState(false);
  const [isMobile] = useMediaQuery(['(max-width: 1123px)']);

 // useTimeoutFn(open, 10000);

  return <Portal
    isOpen={isOpen}
    onClose={close}
    isBottomRight={!isMobile}
    isBgcTransparent={!isMobile}
    isNoPointerEvents={!isMobile}
    isCenter={isMobile}
    isBgcOpacity20={isMobile}
  >
    <ImproveProductPopup close={close}/>
  </Portal>;
};
