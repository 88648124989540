import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { declensionNoun } from 'helpers/declensionNoun';
import { getTextFromMinMaxValue } from 'helpers/getTextFromMinMaxValue';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import { TTour } from 'shared/types/tours.types';
import { TRANSFORM_TEXT, URL_FOR_IMG } from 'shared/constants/const';
import noImage from 'assets/images/noimage.jpg';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { ShareBlog } from 'entities/blog/ShareBlog/ShareBlog';

type TTourItemTopSectionProps = {
  tourItem: TTour;
  handlePortal: () => void;
};

type TParamsTourProps = {
  seasonText?: string;
  groupText: string;
  ageText: string;
  durationText: string;
  priceText: string;
  handlePortal: () => void;
};

const ParamsTour = ({ seasonText, groupText, ageText, durationText, priceText, handlePortal }: TParamsTourProps) => {
  return (
    <>
      <div className='tour-item__params-content'>
        {!!seasonText && (
          <div className='tour-item__params-group'>
            <div className='tour-item__params-name'>Сезон</div>
            <div className='tour-item__params-value'>{seasonText}</div>
          </div>
        )}
        <div className='tour-item__params-group'>
          <div className='tour-item__params-name'>Группа</div>
          <div className='tour-item__params-value'>{groupText}</div>
        </div>
        <div className='tour-item__params-group'>
          <div className='tour-item__params-name'>Возраст</div>
          <div className='tour-item__params-value'>{ageText}</div>
        </div>
        <div className='tour-item__params-group'>
          <div className='tour-item__params-name'>Длительность</div>
          <div className='tour-item__params-value'>{durationText}</div>
        </div>
        <div className='tour-item__params-group'>
          <div className='tour-item__params-name'>Стоимость *1</div>
          <div className='tour-item__params-value'>от {priceText}</div>
        </div>
        <button type='button' className='tour-item__order tour-item__order_desktop' onClick={handlePortal}>
          заказать
        </button>
      </div>
    </>
  );
};

export const TourItemTopSection = ({ tourItem, handlePortal }: TTourItemTopSectionProps) => {
  const { title, productType, preview, operator, minAge, maxAge, minGroupSize, maxGroupSize } = tourItem;
  const history = useHistory();

  const orderMobileRef = useRef<HTMLDivElement>(null);
  const isOrderMobilOnScreen = useOnScreen(orderMobileRef);

  const orderDesktopRef = useRef<HTMLDivElement>(null);
  const isOrderDesktopOnScreen = useOnScreen(orderDesktopRef);

  const priceText = `${tourItem?.price.toLocaleString('ru-RU')} ₽`;
  const ageText = getTextFromMinMaxValue(minAge, maxAge, { key: 'age' });
  const groupText = getTextFromMinMaxValue(minGroupSize, maxGroupSize, { label: 'чел' });
  const durationText = tourItem?.duration ? `${tourItem?.duration} ${declensionNoun(tourItem?.duration, 'day')}` : '-';

  const backHandler = () => {
    history.push('/tours');
  };

  const bg = preview.path ? `${URL_FOR_IMG}/${preview.path.replaceAll(' ', '%20')}` : noImage;

  const nameOperator = operator?.shortName || '';
  const phoneOperator = operator?.phone || operator?.contactPhone || '';
  const emailOperator = operator?.email || operator?.contactEmail || '';
  const moreLink = operator?.moreLink ? (/^https?:\/\//i.test(operator.moreLink) ? operator.moreLink : `http://${operator.moreLink}`) : '';
  const partnerLink = operator?.partnerLink ? (/^https?:\/\//i.test(operator.partnerLink) ? operator.partnerLink : `http://${operator.partnerLink}`) : '';


  let seasonText = '';
  if (
    (tourItem?.summer && tourItem?.spring && tourItem?.winter && tourItem?.autumn) ||
    (!tourItem?.summer && !tourItem?.spring && !tourItem?.winter && !tourItem?.autumn)
  ) {
    seasonText = 'Круглый год';
  } else {
    const seasonArr = [];
    if (tourItem?.summer) {
      seasonArr.push(TRANSFORM_TEXT.season.SUMMER);
    }
    if (tourItem?.autumn) {
      seasonArr.push(TRANSFORM_TEXT.season.AUTUMN);
    }
    if (tourItem?.winter) {
      seasonArr.push(TRANSFORM_TEXT.season.WINTER);
    }
    if (tourItem?.spring) {
      seasonArr.push(TRANSFORM_TEXT.season.SPRING);
    }
    seasonText = seasonArr.join(' ');
  }

  return (
    <div className='tour-item__header-wrapper'>
      <section className='tour-item__header' ref={orderMobileRef}>
        <div className='tour-item__image' style={{ backgroundImage: `url(${bg})` }}></div>
        <button type='button' className='tour-item__back' onClick={backHandler}>
          <ArrowButton className='tour-item__back__icon' />
          Назад
        </button>
        {productType === TRANSFORM_TEXT.productType.EXCURSION && (
          <div className='tour-item__type'>{TRANSFORM_TEXT.productType.EXCURSION}</div>
        )}
        <h1 className='tour-item__title'>
          <span>{title}</span>
        </h1>
        <button
          type='button'
          className={classNames('tour-item__order tour-item__order_mobile', { fixed: !isOrderMobilOnScreen })}
          onClick={handlePortal}
        >
          заказать
        </button>
      </section>
      <section className='tour-item__operator'>
        <div className='container tour-item__operator__container'>
          <div className='tour-item__operator-content'>
            {!!nameOperator && (
              <div
                className='tour-item__operator-name'
                onClick={() => {
                  if (!!partnerLink) {
                    window.open(partnerLink, '_blank', 'noopener,noreferrer');
                  }
                }}
                style={{ cursor: !!partnerLink ? 'pointer' : 'default' }}
              >
                {nameOperator}
              </div>
            )}

            {!!phoneOperator && <div className='tour-item__operator-phone'>{phoneOperator}</div>}
            {!!emailOperator && <div className='tour-item__operator-email'>{emailOperator}</div>}
            {!!moreLink &&
              <a href={moreLink} target='_blank' className='tour-item__operator-more' rel="noreferrer">
                Связаться сейчас
              </a>}

          </div>
        </div>
      </section>
      <section className='tour-item__params'>
        <div className='container tour-item__params__container' ref={orderDesktopRef}>
          <ParamsTour
            seasonText={seasonText}
            groupText={groupText}
            ageText={ageText}
            durationText={durationText}
            priceText={priceText}
            handlePortal={handlePortal}
          />
        </div>
        <ShareBlog />
      </section>

      <div className={classNames('tour-item__params tour-item__params_fixed', { show: !isOrderDesktopOnScreen })}>
        {!isOrderDesktopOnScreen && (
          <div className='container tour-item__container'>
            <div className='tour-item__name'>{tourItem.title}</div>
            <ParamsTour
              groupText={groupText}
              ageText={ageText}
              durationText={durationText}
              priceText={priceText}
              handlePortal={handlePortal}
            />
          </div>
        )}
      </div>
    </div>
  );
};
