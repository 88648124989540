import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { TTerritoryLocation } from 'shared/types/location.types';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import { clearOrder, replaceItemOrder, setReadyOrder } from 'manageStore/designer/designer.slice';
import { selectOrder, selectOrderLocations } from 'manageStore/designer/designer.select';
import { ReactComponent as DeleteIcon } from 'assets/images/common/basket.svg';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { ReactComponent as InfoIcon } from 'assets/images/common/info.svg';
import TourDesignerOrderItem from '../TourDesignerOrderItem/TourDesignerOrderItem';
import { TourDesignerFavorites } from 'features/lib/TourDesignerFavorites/TourDesignerFavorites';

type TTourDesignerOrderListProps = {
  onClose: () => void;
};

const TourDesignerOrderList = ({ onClose }: TTourDesignerOrderListProps) => {
  const dispatch = useDispatch();

  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const order = useSelector(selectOrder);
  const locationsOrder = useSelector(selectOrderLocations);
  const maybeApplication = !!Object.keys(locationsOrder).length;
  const isManyClusters = Object.keys(order).length > 1;

  const handleDragEnd = ({ destination, source }: DropResult, territory: TTerritoryLocation) => {
    const isDrag = destination && destination.index !== source.index;
    if (!isDrag) return;
    dispatch(replaceItemOrder({ oldIndex: source.index, newIndex: destination.index, territory }));
  };

  const handleClearOrder = () => {
    dispatch(clearOrder());
  };

  const handleOrder = () => {
    dispatch(setReadyOrder(true));
  };

  return (
    <div className='designer-order-list'>
      <div className='designer-order-list__header'>
        {isMobile && (
          <>
            <CloseIcon className='designer-order-list__close' onClick={onClose} />
            <h3 className='designer-order-list__title'>Мой маршрут</h3>
          </>
        )}
        {(!maybeApplication || isManyClusters) && (
          <div className='designer-order-list__info'>
            <div className='order-info'>
              <InfoIcon className='order-info__icon' />
              {!maybeApplication && (
                <div className='order-info__text'>Начните собирать свой маршрут, выбирая объекты на карте</div>
              )}
              {isManyClusters && (
                <div className='order-info__text'>
                  Обратите внимание, вы добавили локации из разных кластеров. При подборе тура могут возникнуть
                  сложности или значительное возрастание цены
                </div>
              )}
            </div>
          </div>
        )}
        {maybeApplication && (
          <>
            <div className='designer-order-list__favourites'>
              <TourDesignerFavorites />
            </div>
            <div className='designer-order-list__clear' onClick={handleClearOrder}>
              <div className='clear'>
                <DeleteIcon className='clear__icon' />
              </div>
            </div>
          </>
        )}
      </div>
      <div className='designer-order-list__territory'>
        {Object.entries(order).map(([territory, locations], indexLocation, orderList) => (
          <div key={territory}>
            {orderList.length > 1 && <div className='designer-order-list__territory-title'>{territory}</div>}
            <DragDropContext onDragEnd={(dropResult) => handleDragEnd(dropResult, territory as TTerritoryLocation)}>
              <Droppable droppableId={territory}>
                {(provided) => (
                  <ul className='designer-order-list__cards' ref={provided.innerRef} {...provided.droppableProps}>
                    {locations.map((location, index) => (
                      <Draggable draggableId={location.name} index={index} key={location.name}>
                        {(provided, snapshot) => {
                          const style = {
                            ...provided.draggableProps.style,
                            opacity: snapshot.isDragging ? 0.7 : 1,
                          };
                          return (
                            <li
                              className='designer-order-list__item'
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={style}
                            >
                              <TourDesignerOrderItem
                                location={location}
                                dragHandleProps={provided.dragHandleProps}
                                territory={territory as TTerritoryLocation}
                                index={index}
                              />
                            </li>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ))}
      </div>
      {maybeApplication && (
        <button className='designer-manager-box__order-button' onClick={handleOrder}>
          Заказать путешествие
        </button>
      )}
    </div>
  );
};

export default memo(TourDesignerOrderList);
